module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fahim Faisal","short_name":"Fahim","start_url":"/","icon":"src/images/icon.svg","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"790ed39d79e5bd6e04e810c430ff2933"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
